<template>
  <div class="preview_picture_warp">
    <el-dialog :title="$i18n.locale == 'en' ? 'Preview' : '预览'" :visible.sync="isShow" class="preview_picture_a" center @close="cancel">
      <el-scrollbar style="height:100%">
        <el-image class="preview_img" :src="imgUrl" :draggable="false" @click="magnify = true" />
      </el-scrollbar>
      <img src="~@/assets/operation/magnifying.svg" class="magnifying" @click="magnify = true" />

      <span slot="footer">
        <span style="
                  color: #000000;
                  fontsize: 16px;
                  fontweight: 800;
                  position: relative;
                  top: -23px;
                ">
          {{
            $i18n.locale == "en" ? "Share your MetaWords!" : "分享你的创作"
          }}</span>

        <div>
          <el-button style="background: #080405; color: #efefef" @click="handleDownPicture">
            <i class="el-icon-download"></i>
            {{ $i18n.locale == "en" ? "download" : "下载图片" }}</el-button>
          <el-button style="background: #080405; color: #efefef" @click="handleCopy">
            <i class="el-icon-top-right"></i>
            {{ $i18n.locale == "en" ? "share" : "分享" }}</el-button>
        </div>
      </span>
    </el-dialog>

    <!-- 方案二 -->
    <div class="preview_picture_c" :class="magnify ? '' : 'magnify'" @click="magnify = false">
      <img src="~@/assets/operation/wane.svg" class="wane" />
      <div class="magnify_img">
        <el-scrollbar ref="navscrollbar">
          <el-image :src="imgUrl" :draggable="false" />
        </el-scrollbar>
      </div>
    </div>
    <el-dialog :visible.sync="isTwoShow" @close="cancelTwo" width="240px">
      <vue-qr :text="imageUrl" :size="200"></vue-qr>
    </el-dialog>
  </div>
</template>
<script>
import vueQr from 'vue-qr'
export default {
  name: "PreviewPicture",
  props: {
    flag: String,
  },
  components: {
    vueQr
  },
  data() {
    return {
      isShow: false, //是否打开预览图
      magnify: false, //是否打开预览图放大图
      imgUrl: null,
      share: "Share your MetaWords",
      download: "download",
      isTwoShow: false,
      imageUrl: '',
      baseUrl: ''
      // prefixBase64:"data:image/png;base64,"
    };
  },
  mounted() {
    this.openPreview();
    this.baseUrl = process.env.VUE_APP_BASEURL
  },
  beforeDestroy() {
    this.$off("imgUrl");
  },
  methods: {
    // 取消铸造
    cancel() {
      this.isShow = false;
      this.imgUrl = null;
      this.clearDom();
    },
    cancelTwo() {
      this.isTwoShow = false;
    },
    // 复制链接
    handleCopy() {
      // let url = window.location.href; //浏览器中地址
      // let tempUrl = url.substring(0, url.length - 9);
      // let inputNode = document.createElement("input"); //创建input
      // inputNode.value = tempUrl; //赋值给input值
      // document.body.appendChild(inputNode); //插入进去
      // inputNode.select(); //选择对象
      // document.execCommand("Copy"); //原生调用执行浏览器复制命令
      // inputNode.className = "oInput";
      // inputNode.style.display = "none"; //隐藏
      // this.$message.success(
      //   this.$i18n.locale == "en"
      //     ? "Sharing link is copied to your clipboard."
      //     : "链接已复制"
      // );
      // console.log(this.imgUrl);
      this.isTwoShow = true
    },
    // 下载图片
    async handleDownPicture() {
      let picUrl = this.imgUrl;
      let a = document.createElement("a");
      a.href = picUrl;
      a.setAttribute("download", "file.jpeg");
      a.click();
      this.$message.success(
        this.$i18n.locale == "en" ? "Image saved." : "已下载"
      );
    },
    imageToPng(image) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0, image.width, image.height);
      return canvas.toDataURL("image/jpg");
    },
    //预览
    openPreview() {
      this.$bus.$on("imgUrl", (imgUrl, num, flag) => {
        this.imgUrl = imgUrl;
        this.isShow = flag;
        this.$bus.$emit("closeLoad");
      });
      this.$bus.$on("imageUrl", (val) => {
        this.imageUrl = this.baseUrl + '/#/Preview?src=' + val
      });
    },

    clearDom() {
      //清空拷贝的元素
      const copyDom = document.querySelector(".copy_dom");
      copyDom.innerHTML = "";

      //清空缩略图样式
      const img = document.querySelector(".preview_img");
      img.classList.remove("thumbnail");
    },
  },
};
</script>
<style lang="less" scoped>
.preview_picture_warp {
  .preview_picture_a {
    /deep/.el-dialog {
      margin-top: 4.5vh !important;
      width: calc(52.699479vw + 0.729167vw + 8.333333vw);
      height: 90%;
      position: relative;

      // background-color: black;
      .el-dialog__body {
        position: relative;
        height: calc(100% - 8.979167vw);
        box-sizing: border-box;
        padding: 1.302083vw 4.166667vw;

        .magnifying {
          position: absolute;
          top: 2.083333vw;
          right: 4.947917vw;
          width: 1.041667vw;
          opacity: 0.5;
        }

        .el-image {
          width: calc(52.699479vw + 0.729167vw);
          box-sizing: border-box;
          // border: 0.364583vw solid #000;
        }

        .el-image,
        .magnifying {
          cursor: pointer;
        }
      }

      .el-dialog__footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 1.041667vw;
      }
    }
  }

  // preview_picture_c  放大图
  .preview_picture_c {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 99999;
    padding: 0.520833vw;
    cursor: pointer;
    box-sizing: border-box;

    .wane {
      position: absolute;
      top: 3.645833vw;
      right: 4.947917vw;
      width: 1.5625vw;
      opacity: 0.5;
    }

    .el-image {
      // width: calc(52.699479vw + 0.729168vw);
    }

    .magnify_img {
      width: calc(52.699479vw + 0.729167vw);
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      box-sizing: border-box;
      padding: 0.520833vw 0;
    }


  }
}

/deep/.el-scrollbar {
  height: 100%;

  .el-scrollbar__wrap {
    overflow: hidden;
    overflow-y: auto;
  }

  .el-scrollbar__bar {
    opacity: 0 !important;
  }
}

.thumbnail {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  height: calc(100% - 2.604167vw);
}

.magnify {
  display: none;
}
</style>
