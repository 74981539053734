<template>
  <div>
    <!-- 中英文切换 -->
    <!-- 导航栏 -->
    <nav-page></nav-page>
    <!-- 中间文字区域 -->
    <div class="content-wrap">
      <div class="wrap">
        <div class="wrap-top">
          <span :class="[$i18n.locale == 'en' ? 'enspan1' : 'chspan1']"
            :style="{ fontFamily: $i18n.locale == 'en' ? 'Montserrat-Bold' : 'NotoSansSC-Bold',}">{{ $t('aboutUs.aboutUs')}}</span>
          <span
            :style="{fontFamily: $i18n.locale == 'en' ? 'Montserrat-Regular' : 'NotoSansSC-Regular', }">{{ $t('aboutUs.text1')}}</span>
          <span
            :style="{fontFamily: $i18n.locale == 'en'? 'Montserrat-Regular' : 'NotoSansSC-Regular',}">{{ $t('aboutUs.text2') }}</span>
          <span
            :style="{fontFamily: $i18n.locale == 'en' ? 'Montserrat-Regular': 'NotoSansSC-Regular',}">{{ $t('aboutUs.text3') }}</span>
        </div>
        <div style=" width: 37px; height: 1px; border: 5px solid #181726; margin-top: 44px; background: #181726;"></div>
        <div class="wrap-bottom">
          <span
            :style="{fontFamily: $i18n.locale == 'en' ?' Montserrat-Bold' : ' NotoSansSC-Bold'}">{{ $t('aboutUs.text4') }}</span>
          <span
            :style="{fontFamily: $i18n.locale == 'en' ? 'Montserrat-Medium' : 'NotoSansSC-Regular'}">{{ $t('aboutUs.text5') }}</span>
          <span
            :style="{fontFamily: $i18n.locale == 'en' ? 'Montserrat-Medium' : 'NotoSansSC-Regular'}">{{ $t('aboutUs.text6') }}</span>
          <span
            :style="{fontFamily: $i18n.locale == 'en' ? 'Montserrat-Medium' : 'NotoSansSC-Regular',}">{{ $t('aboutUs.text7') }}</span>
          <span
            :style="{fontFamily: $i18n.locale == 'en' ? 'Montserrat-Medium' : 'NotoSansSC-Regular'}">{{ $t('aboutUs.text8') }}</span>
        </div>
      </div>
    </div>
    <footer-page></footer-page>
  </div>
</template>
<script>
// import headerPage from "@/components/header.vue"
import navPage from "@/components/header_nav.vue"
import footerPage from "@/components/footer.vue"//脚部

export default {
  components: { navPage, footerPage },
  data() {
    return {

    };
  },
};
</script>
<style scoped>
</style>
<style lang='less' scoped>
// @import "../assets/commonFont/commom.css";

.content-wrap {
  min-width: 1500px;
  margin: 0 auto;
  height: 100%;
  .wrap {
    width: 1050px;
    margin: 0 auto;
    .wrap-top {
      span {
        display: block;
      }
      span:nth-child(1) {
        font-size: 26px;
        font-weight: bold;
        color: #000000;
        line-height: 37px;
        margin-top: 88px;
      }
      span:nth-child(2),
      span:nth-child(3),
      span:nth-child(4) {
        font-size: 21px;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        margin-top: 43px;
      }
    }

    .wrap-bottom {
      margin-bottom: 100px;
      span {
        display: block;
      }
      span:nth-child(1) {
        font-size: 26px;
        font-weight: bold;
        color: #000000;
        line-height: 37px;
        margin-top: 43px;
      }
      span:nth-child(2) {
        margin-top: 43px;
      }
      span:nth-child(2),
      span:nth-child(3),
      span:nth-child(4),
      span:nth-child(5) {
        font-size: 21px;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
      }
    }
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 350px;
  min-width: 1700px;
  background: #000000;
  .footer-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 100px;
    width: 1000px;
    .left {
      display: flex;
      align-items: center;
      margin-bottom: 70px;
      .logo {
        width: 61px;
        height: 61px;
      }
      .logo-text {
        font-size: 24px;
        font-family: Montserrat-Bold, Montserrat;
        font-weight: bold;
        color: #ffffff;
        padding-left: 29px;
      }
    }
    .center {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .enfooterspan {
        width: 172px;
        font-size: 17px;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
      }
      .chfooterspan {
        width: 172px;
        font-size: 17px;
        font-family: NotoSansSC-Bold, NotoSansSC;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
      }
      span:nth-child(1) {
        padding-top: 21px;
      }
      span:nth-child(2) {
        display: block;
        margin-top: 21px;
        margin-bottom: 21px;
      }
    }
    .right {
      margin-bottom: 60px;
      a:nth-child(1) {
        margin-right: 22px;
      }
      a:nth-child(2) {
        margin-right: 22px;
      }
    }
  }
}
</style>
