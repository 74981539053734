<template>
  <div>
    <!-- header start -->
    <div class="operation-header flex a-c">
      <p @click="handleBack">
        <i class="el-icon-back"></i>
        {{ $i18n.locale == 'en' ? 'Back' : '返回' }}
      </p>
      <el-button type="primary" class="flex a-c j-c" :loading="previewLoading" @click="preview">
        {{ $i18n.locale == 'en' ? 'Preview' : '预览' }}
      </el-button>
    </div>
    <!-- header end -->
    <div class="subject flex">
      <!-- operation left start-->
      <div class="operation-type flex">
        <!-- 左侧列表区域 -->
        <div class="left">
          <ul>
            <el-scrollbar ref="navscrollbar">
              <li v-for="item in dataAll" :key="item.ds_cate_id" class="flex a-c list-wrap" :class="actions === item.ds_cate_id ? 'active' : ''" @click="handleType(item.ds_cate_id)">
                <div class="img-wrap">
                  <img :src="item.ds_icon" :draggable="false" class="mark" />
                </div>
                <div class="text-wrap">
                  <span class="list-title">
                    {{ $i18n.locale == "en" ? item.ds_cate_name_en : item.ds_cate_name_cn }}
                  </span>
                </div>
              </li>
            </el-scrollbar>
          </ul>
        </div>
        <!-- 右侧搜索区域 -->
        <div class="right">
          <div>
            <el-input placeholder="Please enter the content" v-model="keyword" @keyup.native='enterSearch'>
              <template slot="append">
                <img src="~@/assets/images/search-line.png" style="cursor: pointer;width:1.3vw;padding-top:0.15625vw" alt="" @click="enterSearch">
              </template>
            </el-input>
          </div>
          <!-- <el-autocomplete prefix-icon ='el-icon-search' v-model="state" :fetch-suggestions="querySearchAsync" :trigger-on-focus="false" :placeholder="$i18n.locale=='en'?'Please enter the content':'请输入内容'" @select="handleSelect" /> -->
          <div class="right_img_wrap">
            <el-empty v-if="loading" v-loading="loading" />
            <template v-else>
              <h4 class="flex a-c title">
                <div style="width:17.96875vw;height:2vw">
                  <span class="title_name" style="display:block;height:1.4vw;width:12vw;margin-left:0.83333vw;">
                    {{ imgListName }}
                  </span>
                  <span style="position:absolute;right:1vw;top:0vw">
                    <img class="roate" src="~@/assets/images/mirror.svg" alt="" @click="rotatingClick">
                    <img class="mark-icon" src="~@/assets/operation/mark.svg" @mouseover="instruction = true" @mouseout="instruction = false" />
                  </span>
                  <div v-if="$i18n.locale == 'en'">
                    <img v-show="instruction" src="~@/assets/operation/Instruction-English.png" class="instruction" />
                  </div>
                  <div v-else-if="$i18n.locale == 'zh'">
                    <img v-show="instruction" src="~@/assets/operation/Instruction-Chinese.png" class="instruction" />
                  </div>
                </div>
              </h4>
              <div class="right_img">
                <el-scrollbar ref="navscrollbar">
                  <img class="animation" :class="flagTrsn ? 'transform180' : ''" ref="imgRef" :src="l.img_addr" :draggable="false" v-for="(l, i) in imgList" :key="i" @click="addItem2editor(l)" />
                </el-scrollbar>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- operation left end-->
      <!-- operation right start-->
      <div class="operation_work_wrap">
        <div class="operation_work">
          <div id="editor"></div>
        </div>
        <div class="copy_dom">
        </div>
      </div>
      <!-- operation right end-->
    </div>
  </div>
</template>
<script>
import domtoimage from '../assets/js/dom-to-image.min.js'
import { uploadFile, saveUserCharacterText, getlist, getSearchList } from "@/api/operation";
import { rmSync } from 'fs';
import E from "wangeditor";
import _ from 'lodash';
let that = null;
export default {
  name: "Creattive",
  props: {
    flag: String,
  },
  data() {
    return {
      show: false, // 预览图显示标志
      loading: false,
      previewLoading: false,
      instruction: false, //是否显示玩法(？图标)弹窗
      flagTrsn: false, //翻转标识
      focusState: false,
      actions: 1, //默认初次显示类别
      imgListName: '',//列表名称
      keyword: '',
      dataAll: [],//总数据
      imgList: [], // 图片列表
      resultJson: [],
      resultJson1: [],
      editor: null, //编辑器
      isKeyboard: true, //是否要禁用键盘
      maxRows: 50,
      initNum: 299,
      initCharNum: 199,
      maxAllAmounts: 299, // 字符与标点的总数量，最大299
      maxCharAmounts: 199, //字符数量，最大199
    };
  },
  mounted() {
    that = this;
    this.loadGetTypeList();
    this.seteditor(); //初始化编辑器
    // this.keyupEvent();
  },
  watch: {

  },
  methods: {
    handleBack() {
      this.$router.push("/Home")
    },
    // 预览作品
    async preview() {
      if (this.maxAllAmounts == this.initNum) {
        this.$message.warning(this.$i18n.locale == 'en' ? "没有任何作品内容" : "没有任何作品内容");
        return false;
      }
      await this.formatData();
      await this.copyDom();

    },
    // 激活当前标签
    handleType(id) {
      this.actions = id;
      this.keyword = '';
      this.loadGetImgList();
    },
    //初始化富文本
    seteditor() {
      this.editor = new E("#editor");
      // 配置菜单
      this.editor.config.menus = [];
      // 取消预占位文本提示
      this.editor.config.placeholder = "";
      // 取消全屏显示
      this.editor.config.showFullScreen = false;
      //复制粘贴带样式
      this.editor.config.pasteFilterStyle = false;
      // 配置粘贴文本的内容处理
      this.editor.config.pasteTextHandle = function (pasteStr) {
      }
      //配置删除事件
      this.editor.txt.eventHooks.deleteUpEvents.push(this.delete2editor);
      this.editor.txt.eventHooks.keyupEvents.push(this.keyupEvent)
      this.editor.txt.eventHooks.enterUpEvents.push(this.enterUp)
      this.editor.txt.eventHooks.pasteEvents.push(this.pasteEvent)
      // 创建富文本编辑器
      this.editor.create();
      //失去焦点
      this.editor.config.onblur = function () {
        that.isKeyboard = false;
      };
      // //获取焦点
      this.editor.config.onfocus = function () {
        that.isKeyboard = true;
      };
    },
    // 旋转字符
    rotatingClick() {
      this.flagTrsn = !this.flagTrsn;
    },
    // 字符搜索
    async enterSearch() {
      if (this.keyword != "") {
        const list = await getSearchList({
          name: this.keyword,
        });
        if (list.data.data) {
          this.imgList = list.data.data;
          this.imgListName = this.keyword;
        } else {
          this.$message.warning("No relevant character found.");//未找到相关字符
        }
      } else {
        this.loadGetImgList()
      }
    },
    // 粘贴内容过滤
    pasteEvent(e) {
      // 计算作品的内容数量
      const conLenState = that.calcCharLen();
      if (!conLenState) {
        that.$message.warning("The copied character exceeds the maximum limit");
        return false;
      }
      // 内容过滤
      const pasteCon = e.clipboardData.getData('text/html')
      if (!pasteCon) return false;
      let frag = document.createElement('div');
      frag.innerHTML = pasteCon;
      const r = frag.querySelectorAll('*');
      for (let i of r) {
        if (i.tagName.toLowerCase() == 'img') {
          if (_.includes(i.getAttribute('src'), 'lion')) {
            this.editor.cmd.do('insertHTML', i.outerHTML);
          }
        }
      }
    },
    // 统计内容区域当前字符的数量,返回是否可以继续添加内容元素,
    calcCharLen() {
      // if (this.editor.txt.html()) {
      // // 初始化变量
      this.maxAllAmounts = this.initNum;
      this.maxCharAmounts = this.initCharNum;
      const contentJson = this.editor.txt.getJSON();
      contentJson.map(e => {
        e.children.map(c => {
          // 图片类型
          if (typeof c == 'object' && c.tag == 'img') {
            const typeId = _.find(c.attrs, { name: 'typeid' })?.value;
            if (typeId && typeId == 58) {
              this.maxAllAmounts -= 1;
            } else {
              this.maxAllAmounts -= 1;
              this.maxCharAmounts -= 1;
            }
          }
        })
      })
      // console.log(`剩余可创作数量:${this.maxAllAmounts}, 字符剩余:${this.maxCharAmounts}`);
      if (this.maxAllAmounts <= 0 || this.maxCharAmounts <= 0) {
        // if (this.maxAllAmounts <= (this.picAmounts + this.charAmouns) || this.maxCharAmounts <= this.charAmouns) {
        return false;
      }
      return true;
      // } else {
      //   return true;
      // }
    },
    // 组合数据
    getResult() {
      this.resultJson = [];
      const contentJson = this.editor.txt.getJSON();
      contentJson.map(e => {
        const itemList = [];
        e.children.map(c => {
          let itemTxt = "";
          if (typeof c == 'object' && c.tag == 'img') {
            const id = _.find(c.attrs, { name: 'id' })?.value;
            const rotate = _.find(c.attrs, { name: 'rotate' })?.value;
            itemTxt = `#${id}#,${rotate}`
          } else if (typeof c == 'string' && !_.trim(c)) {
            itemTxt = _.repeat(" ", c.length)
          }
          itemList.push(itemTxt)
        })
        this.resultJson.push(itemList.join("|"))
      })
    },
    // 字符添加
    addItem2editor(item) {
      // 1.判断是否可以继续添加元素
      // 2.添加元素到内容区
      const conLenState = this.calcCharLen();
      if (!conLenState) {
        if (this.maxAllAmounts <= 0) {
          this.$message.warning(this.$i18n.locale == 'en' ? "The copied character exceeds the maximum limit" : "内容已达到最大上限");
          return false;
        }
        if (this.maxCharAmounts <= 0) {
          this.$message.warning(this.$i18n.locale == 'en' ? "The number of copies exceeds the maximum limit" : "字符已达到最大上限");
          return false;
        }
      };
      const rotate = this.flagTrsn ? 180 : 0
      const imgNode = `<img src="${item.img_addr}" draggable="false" style="height:1.94vw; vertical-align: middle; ${this.flagTrsn ? 'transform: rotateY(180deg);' : ''}" id="${item.id}" rotate="${rotate}" typeId="${item.typeId}">`;
      this.editor.cmd.do("insertHTML", imgNode);
    },
    // delete按键事件
    delete2editor(e) {
      this.calcCharLen();
    },
    // 按键事件处理
    keyupEvent() {
      // 允许通过的按键码
      let uniteKeys = [65, 67, 86, 88, 90]
      let allowKeys = [8, 13, 32, 46, 37, 38, 39, 40, ...uniteKeys]
      const container = document.getElementsByClassName('w-e-text')[0]
      container.onkeydown = function (e) {
        const allowState = _.includes(allowKeys, e.keyCode)
        const composed = _.includes(uniteKeys, e.keyCode) && (e.ctrlKey || e.composed)
        if (!allowState && !composed) {
          return false;
        }
      }
    },
    formatData() {
      this.resultJson1 = [];
      const initHtml = this.editor.txt.html();
      let frag = document.createElement('div');
      frag.innerHTML = initHtml;
      const r = frag.querySelectorAll('p');
      const headMax = _.slice(r, 0, this.maxRows);
      for (let i of headMax) {
        const sNode = i.querySelectorAll('*');
        if (sNode.length) {
          const sNodeList = []
          for (let j of sNode) {
            if ((j.tagName.toLowerCase() == 'img' && _.includes(j.getAttribute('src'), 'lion')) || j.tagName.toLowerCase() == 'br') {
              sNodeList.push(j.outerHTML)
            }
          }
          this.resultJson1.push(`<p>${sNodeList.join('')}</p>`)
        }
      }
      if (this.resultJson1.length) {
        this.editor.txt.clear();
        this.editor.cmd.do("insertHTML", this.resultJson1.join(''));
      }
    },
    // 回车键事件及内容处理
    enterUp(e) {
      const jd = this.editor.txt.getJSON()
      if (jd && jd.length > this.maxRows) {
        this.editor.txt.setJSON(_.slice(jd, 0, this.maxRows))
      }
    },

    // 复制节点
    async copyDom() {
      this.previewLoading = true;
      await this.getResult();
      const bg = sessionStorage.getItem('bg')
      const copy_dom = document.querySelector(".copy_dom");
      const content = this.editor.txt.getJSON();
      for (let i of content) {
        i.attrs.push({ name: 'style', value: 'line-height:3.680556vw;' });
      }
      this.editor.txt.setJSON(content)
      copy_dom.innerHTML = bg + this.editor.txt.html();
      setTimeout(() => {
        this.cut();
      }, 2000)
    },
    // 截取图片
    async cut() {
      try {
        const node = document.querySelector('.copy_dom');
        const imgUrlJpeg = await domtoimage.toJpeg(node, { quality: 1, bgcolor: '#fff' });
        this.$bus.$emit('imgUrl', imgUrlJpeg, '', !this.isShow);
        let fileData = await this.dataURLtoFile(imgUrlJpeg, 'file')
        // 上传图片
        const res = await uploadFile(fileData)
        if (res.data.code == 0) {
          this.$bus.$emit('imageUrl', res.data.data.objectid);
          let formDatas = new FormData()
          formDatas.append('picurl', res.data.data.objectid)
          formDatas.append('txt', JSON.stringify(this.resultJson))
          saveUserCharacterText(formDatas).then(res => {
            if (res.data.code == 0) {
              this.$message.success(this.$i18n.locale == 'en' ? "Success!" : "成功！")
            } else if (res.data.msg != 'The content is repetitive') {
              this.$message.error(res.data.msg)
            }
          })
        }
      } catch (error) {
        this.$message.error(this.$i18n.locale == 'en' ? "Picture loading failed" : "图片加载失败")
      }
      this.previewLoading = false;
    },
    //将base64转换为文件
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(",")
      let mime = arr[0].match(/:(.*?);/)[1]
      let suffix = mime.split('/')[1];
      let bstr = window.atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let file = new File([u8arr], `${filename}.${suffix}`, { type: mime })
      let fileObj = new FormData()
      fileObj.append('file', file)
      return fileObj
    },

    // 获取所有字符的数据
    async loadGetTypeList() {
      const { data: { data } } = await getlist();
      // 以 ds_cate_id 排序
      this.dataAll = [...data];
      // this.dataAll = _.orderBy([...data], ['ds_cate_id']);
      this.actions = _.head(this.dataAll)?.ds_cate_id;
      await this.loadGetImgList();
    },
    // 获取当前激活标签的图片数据
    async loadGetImgList() {
      this.loading = true;
      this.flagTrsn = false;
      const obj = this.dataAll.find((item) => item.ds_cate_id === this.actions);
      obj.data.map(e => {
        e.typeId = obj.ds_cate_id
      })
      this.imgList = obj.data;
      this.imgListName = obj['ds_cate_name_' + this.$i18n.locale];
      this.loading = false;
    }
  },
};
</script>
<style lang='less' scoped>
.operation-header {
  justify-content: space-between;
  height: 3.125vw;
  padding: 0 0.78125vw;
  background: #121214ff;
  box-shadow: 0 0.260417vw 1.041667vw #c2c2c2;

  p {
    font-size: 0.833333vw;
    font-weight: 600;
    color: #ffffff;
    line-height: 1.145833vw;
    cursor: pointer;
  }

  .el-button {
    width: 5.16vw;
    height: 2vw;
    padding: 0.625vw 1.041667vw;
    font-size: 0.729167vw;
  }
}


.title {
  display: flex;
  justify-content: space-between;

  &-icon {
    position: absolute;
    right: 20px;
  }
}

.operation-type {
  width: 31.666667vw;
  height: 100%;
  background-color: #fff;
  user-select: none;

  /deep/.el-scrollbar {
    height: 100%;

    .el-scrollbar__wrap {
      overflow: hidden;
      overflow-y: auto;
      width: 19vw;
      padding: 0 0.5vw;
    }
  }

  // left
  .left {
    width: 11.5vw;
    height: 100vh;

    .list-wrap {
      .img-wrap {
        width: 1.8vw;
      }

      .text-wrap {
        width: 5.5vw;
        text-align: left;
      }
    }

    ul {
      width: 11.5vw;
      height: 100%;
      box-sizing: content-box;
      text-align: center;

      li {
        height: 3.125vw;
        padding-left: 0.416667vw;
        cursor: pointer;
        font-size: 0.833333vw;

        img {
          height: 1.94vw;
          -webkit-user-drag: none;
        }

        &:hover {
          background-color: #efefef;
        }
      }

      .active {
        background-color: #efefef;
      }

      .list-title {
        display: block;
        font-size: 13px;
        padding-left: 1.7vw;
        width: 5vw;
      }
    }
  }

  // 右侧
  .right {
    padding: 0.833333vw 1.458333vw 0 0.858333vw;

    .el-input {
      width: 17.96875vw;
      margin-left: 0.416667vw;
      background: #ffffff;
      border-top-left-radius: 0.416667vw;
      font-size: 0.729167vw;

      .el-input__inner {
        border-left: 0.052083vw solid #dcdfe6;
        border-bottom: 0.052083vw solid #dcdfe6;
        border-top: 0.052083vw solid #dcdfe6;
        padding-left: 0.83333vw;
        padding-top: 0.52083vw;
        padding-bottom: 0.52083vw;

      }

      /deep/.el-input__inner {
        border-right: none;
        padding-right: 0;
      }

      /deep/.el-input-group__append,
      .el-input-group__prepend {
        background-color: #ffffff;
        padding: 0 0.2604vw;
      }

      /deep/.el-input-group__append {}
    }

    /deep/ .el-input__inner:focus {
      border-color: #dcdfe6;
    }

    /deep/ .el-input__inner:hover {
      border-color: #DCDFE6;
    }

    .right_img_wrap {
      margin-top: 1.25vw;

      h4 {
        position: relative;
        justify-content: space-between;
        padding: 0 0.416667vw;
        font-size: 0.833333vw;
        color: #09090a;

        .mark {
          width: 0.833333vw;
          cursor: pointer;
        }

        .instruction {
          position: absolute;
          right: -400px;
          top: -20px;
          width: 400px;
          z-index: 500;
          border-radius: 10px;
        }

        .roate {
          width: 1.6vw;
          height: 1.6vw;
          margin-right: 20px;
          cursor: pointer;
        }

        .mark-icon {
          width: 1.4vw;
          height: 1.4vw;
          cursor: pointer;
        }
      }

      .right_img {
        height: calc(100vh - 11.458333vw);
        overflow: hidden;
        margin-top: 0.929167vw;

        img {
          cursor: pointer;
          width: 2.191667vw;
          height: 1.94vw;
          margin: 0.833333vw 0.417vw 0;
          -webkit-user-drag: none;
        }

        .animation {
          transition: All 0.5s ease-in-out;

          &:hover {
            transform: scale(1.7);
          }
        }

        .transform180 {
          // transition: All 0.5s ease-in-out;
          transform: rotateY(180deg);

          &:hover {
            transform: rotateY(180deg) scale(1.7);
          }
        }
      }
    }
  }
}

.operation_work_wrap {
  position: relative;
  flex: 1;
  overflow: hidden;

  .operation_work {
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 2.291667vw 0 4.583333vw;
    box-sizing: border-box;
    background-color: #efefef;
    z-index: 10;

    /deep/#editor {
      outline: none;
      box-sizing: border-box;
      height: 100%;
      // height: 300px;
      width: 52.7vw;
      margin: 0 auto;
      box-shadow: 0px 2px 6px rgba(36, 36, 36, 0.17);

      .w-e-toolbar {
        border: none !important;
      }

      .w-e-text-container {
        height: 100% !important;
        width: 100% !important;
        border: none !important;
        z-index: 100 !important;

        .w-e-text {
          padding: 3.680556vw 4.236111vw;

          p {
            line-height: 3.680556vw !important;
            margin: 0;

            img {
              -webkit-user-drag: none;

              &:hover {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }

  .copy_dom {
    position: relative;
    box-sizing: border-box;
    width: calc(52.699479vw + 0.729167vw);
    padding: 3.780556vw 4.136111vw;
    border: 0.364583vw solid #000;
    background: url('../assets/operation/bg1.svg') no-repeat right bottom;
    background-size: 3vw 3vw;
    background-position: 101% 102%;

    .bg {
      position: absolute;
      width: 3vw;
      z-index: 1;
    }
  }
}
</style>
