<template>
  <div>
     <!-- 中英文切换 -->
    <!-- <header-page></header-page> -->
    <!-- 导航栏 -->
    <nav-page :opacityFlag='opacityFlag'></nav-page>
    <div class="red">
      <div class="container-wrap">
        <!-- 导航栏下图片区域 -->
        <div class="photo-wrap">
          <div class="left">
            <img src="../assets/images/book_icon1.png" />
            <img src="../assets/images/book_author.png" />
            <img src="../assets/images/book_location.png" />
            <img src="../assets/images/book_year.png" />
            <img src="../assets/images/book_icon2.png" />
          </div>
          <div class="right">
            <span :class="[$i18n.locale == 'en' ? 'eactive1' : 'cactive1']">{{$t('book.book')}}</span>
            <span style="display: block" :class="[$i18n.locale == 'en' ? 'eactive2' : 'cactive2']">{{$t('book.text1')}}
              <span v-show="$i18n.locale == 'en'">
                "<img
                  class="people"
                  src="../assets/images/book_icon3.png"
                  alt=""
                />"
                {{ $t('book.text1Copy')}}
              </span>
            </span>
            <span style="display: block" :class="[$i18n.locale == 'en' ? 'eactive3' : 'cactive3']">{{  $t('book.text2')}}</span>
            <!-- 播放按钮 -->
            <div class="video" :style="{ marginTop: $i18n.locale == 'en' ? '0px' : '43px' }">
              <a href="https://lionworld.oss-cn-hongkong.aliyuncs.com/MetaWords+from+Xu+Bing.mp4" target="_blank">
                <img class="play-btn" src="../assets/images/book_icon4.png" />
              </a>
              <img src="https://lionworld.oss-cn-hongkong.aliyuncs.com/metawords/source-4.png" alt=""/>
            </div>
            <div class="land-book">
              <p>{{ $i18n.locale == 'en' ? "Pages from the Book" : "《地书》內页" }}</p>
              <img src="../assets/images/book_icon5.png" class="picture"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-wrap">
      <!-- 底部区域 -->
      <div class="bottom-wrap">
        <!-- 图片 -->
        <div class="bottom-warp-top">
          <img src="../assets/images/book_bitmap.png" />
          <img src="../assets/images/book_icon6.png" />
          <img src="../assets/images/book_icon7.png" />
          <img src="../assets/images/book_icon8.png" />
          <img src="../assets/images/home_logo.svg" />
        </div>
        <!-- 文字 -->
        <div class="bootom-wrap-text">
          <span :class="[$i18n.locale == 'en' ? 'etextActive' : 'ctextActive']">{{$t('book.footerText')}}</span>
          <span :class="[$i18n.locale == 'en' ? 'etextActive' : 'ctextActive']">{{$t('book.footerText1')}}</span>
          <span :class="[$i18n.locale == 'en' ? 'etextActive' : 'ctextActive']">{{$t('book.footerText2')}}</span>
          <span :class="[$i18n.locale == 'en' ? 'etextActive' : 'ctextActive']">{{$t('book.footerText3')}}</span>
        </div>
        <!-- 步骤条图片 -->
        <div class="bootom-wrap-img">
           <img src="../assets/images/book_icon9.png">
        </div>
        <!-- 步骤条下文字 -->
        <div class="bottom-warp-greenText">
          <span :class="[$i18n.locale == 'en' ? 'etextActive' : 'ctextActive']">{{$t('book.footerText4')}}</span>
          <span :class="[$i18n.locale == 'en' ? 'etextActive' : 'ctextActive']">{{$t('book.footerText5')}}</span>
          <span :class="[$i18n.locale == 'en' ? 'etextActive' : 'ctextActive']">{{$t('book.footerText6')}}</span>
        </div>
        <!-- 最底部图片 -->
        <div class="bottom-warp-green">
          <img src="../assets/images/book_icon10.png" />
          <img src="../assets/images/book_icon11.png" />
          <img src="../assets/images/book_icon12.png" />
        </div>
      </div>
    </div>
    <footer-page></footer-page>
  </div>
</template>
<script>
// import headerPage from "@/components/header.vue"
import navPage from "@/components/header_nav.vue"//导航栏
import footerPage from "@/components/footer.vue"//脚部

export default {
  components:{navPage,footerPage},
  data() {
    return {
      opacityFlag: 2,
    };
  },

};
</script>
<style scoped>
</style>
<style lang="less" scoped>

.container-wrap {
  margin: 0 auto;
  height: 100%;
  width: 1500px;
}
// 图片区域样式
.red {
  background-image: url("../assets/images/book_icon13.png");
  background-position-y: 92px;
  background-repeat: no-repeat;
  height: 1260px;
  min-width: 1500px;
  width: 100%;
  background-size: cover;
}
.photo-wrap {
  display: flex;
  margin: 50px auto;
  width: 1000px;
  .left {
    img {
      display: block;
    }
    img:nth-child(1) {
      width: 453px;
      height: 378px;
      margin-left: -180px;
    }
    img:nth-child(2) {
      width: 300px;
      height: 48px;
    }
    img:nth-child(3) {
      width: 300px;
      height: 48px;
    }
    img:nth-child(4) {
      width: 300px;
      height: 48px;
    }
    img:nth-child(5) {
      margin-top: 97px;
      width: 475px;
      height: 482px;
    }
  }
  .right {
    margin-left: -150px;
    .people {
      position: relative;
      top: 5px;
    }
    .eactive1 {
      font-size: 41px;
      font-family: Montserrat-Bold, Montserrat;
      font-weight: bold;
      color: #171826;
    }
    .cactive1 {
      font-size: 50px;
      font-family: NotoSansSC-Bold, NotoSansSC;
      font-weight: bold;
      color: #171826;
      line-height: 72px;
    }
    .eactive2 {
      width: 655px;
      font-size: 17px;
      font-family: Montserrat-Regular, Montserrat;
      font-weight: 400;
      color: #171826;
      line-height: 20px;
      margin-top: 31px;
    }
    .cactive2 {
      width: 670px;
      font-size: 20px;
      font-family: NotoSansSC-Regular, NotoSansSC;
      font-weight: 400;
      color: #171826;
      line-height: 29px;
      margin-top: 36px;
    }
    .eactive3 {
      width: 655px;
      font-size: 17px;
      font-family: Montserrat-Regular, Montserrat;
      font-weight: 400;
      color: #171826;
      line-height: 18px;
      margin-bottom: 30px;
      margin-top: 18px;
    }
    .cactive3 {
      width: 670px;
      font-size: 20px;
      font-family: NotoSansSC-Regular, NotoSansSC;
      font-weight: 400;
      color: #171826;
      line-height: 29px;
      margin-bottom: 45px;
      margin-top: 22px;
    }
    .video {
      position: relative;
      margin: 40px 0 0 10px;
      .play-btn {
        position: absolute;
        top: 50%;
        left: 47%;
        transform: translate(-47%, -50%);
        z-index: 9999;
      }
      img:nth-child(2) {
        height: 357px;
        width: 637px;
      }
    }
    .land-book {
      display: flex;
      margin-top: 63px;
      p {
        margin-left: 40px;
        width: 132px;
        height: 43px;
        font-size: 20px;
        font-family: NotoSansSC-Regular, NotoSansSC;
        font-weight: 400;
        color: #171826;
        line-height: 29px;
      }
      img {
        margin-left: 26px;
        width: 483px;
        height: 335px;
      }
    }
  }
}

// 底部区域样式
.bottom-wrap {
  margin: 30px auto 0;
  // width: 55%;
  width: 1200px;
  .bottom-warp-top {
    display: flex;
    margin-left: 72px;
    // justify-content: space-between;
    // padding: 0 160px 0 80px;
    img:nth-child(1) {
      margin-left: 90px;
      height: 100px;
      width: 61px;
    }
    img:nth-child(2) {
      margin-left: 134px;
      height: 100px;
      width: 123px;
    }
    img:nth-child(3) {
      margin-left: 105px;
      height: 100px;
      width: 94px;
    }
    img:nth-child(4) {
      margin-left: 10px;
      height: 93px;
      width: 101px;
    }
    img:nth-child(5) {
      margin-left: 150px;
      height: 93px;
      width: 93px;
    }
  }

  .bootom-wrap-text {
    display: flex;
    align-items: flex-end;
    margin-top: 17px;
    width: 1147px;
    margin-left: 72px;
    .ctextActive {
      font-size: 16px;
      font-family: NotoSansSC-Medium, NotoSansSC;
      font-weight: 500;
      color: #171826;
    }
    .etextActive {
      font-size: 16px;
      font-family: Montserrat-Medium, Montserrat;
      font-weight: 500;
      color: #171826;
    }
    span:nth-child(1) {
      width: 250px;
    }
    span:nth-child(2) {
      margin-left: 40px;
      width: 144px;
    }
    span:nth-child(3) {
      margin-left: 160px;
      width: 147px;
    }
    span:nth-child(4) {
      margin-left: 50px;
      width: 273px;
    }
  }
  // 步骤条图片样式
  .bootom-wrap-img {
    margin: 47px 20px 47px 0;
    padding-right: 30px;
    width: 1147px;
    height: 110px;
  }
  // 步骤条图片下文字样式
  .bottom-warp-greenText {
    display: flex;
    width: 1147px;
    margin-left: 72px;
    .ctextActive {
      font-size: 16px;
      font-family: NotoSansSC-Medium, NotoSansSC;
      font-weight: 500;
      color: #171826;
    }
    .etextActive {
      font-size: 16px;
      font-family: Montserrat-Medium, Montserrat;
      font-weight: 500;
      color: #171826;
    }
    span {
      display: block;
    }
    span:nth-child(1) {
      height: 72px;
      width: 230px;
    }
    span:nth-child(2) {
      margin-left: 50px;
      height: 48px;
      width: 145px;
    }
    span:nth-child(3) {
      margin-left: 370px;
      height: 72px;
      width: 273px;
    }
  }
  // 最底部图片样式
  .bottom-warp-green {
    display: flex;
    align-items: center;
    margin: 56px 0 50px 72px;
    img:nth-child(1) {
      margin-left: 40px;
      width: 113px;
      height: 33px;
    }
    img:nth-child(2) {
      margin-left: 160px;
      width: 101px;
      height: 77px;
    }
    img:nth-child(3) {
      margin-left: 460px;
      width: 93px;
      height: 93px;
    }
  }
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 350px;
  min-width: 1500px;
  background: #000000;
  .footer-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 100px;
    width: 1000px;
    .left {
      display: flex;
      align-items: center;
      margin-bottom: 70px;
      .logo {
        width: 61px;
        height: 61px;
      }
      .logo-text {
        font-size: 24px;
        font-family: Montserrat-Bold, Montserrat;
        font-weight: bold;
        color: #ffffff;
        padding-left: 29px;
      }
    }
    .center {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .enfooterspan {
        width: 172px;
        font-size: 17px;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
      }
      .chfooterspan {
        width: 172px;
        font-size: 17px;
        font-family: NotoSansSC-Bold, NotoSansSC;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
      }
      span:nth-child(1) {
        padding-top: 21px;
      }

      span:nth-child(2) {
        display: block;
        margin-top: 21px;
        margin-bottom: 21px;
      }
    }
    .right {
      margin-bottom: 60px;
      a:nth-child(1) {
        margin-right: 22px;
      }
      a:nth-child(2) {
        margin-right: 22px;
      }
    }
  }
}
</style>