<template>
  <div>
     <!-- 中英文切换 -->
    <!-- 导航栏 -->
    <nav-page  :opacityFlag='opacityFlag'></nav-page>
    <!-- 导航栏下图片区域 -->
    <div class="content-img">
       <img v-if="$i18n.locale == 'en'" src="../assets/images/abor_logo_copy.png" />
       <img v-else src="../assets/images/abor_logo.png" alt="" />
    </div>
    <!-- 中间文字样式 -->
    <div class="content-wrap">
      <div class="fs21 wid997" :class="[$i18n.locale == 'en' ? 'enText1 fm14 line25' : 'chText1 fm12 line30']">
        {{ $t('aboriginal.content.title')}}
      </div>
      <div class="fs21 fm14 wid997 line25 mt4" v-if="$i18n.locale == 'en'">{{ $t('aboriginal.content.title3')}}</div>
      <div class="wid997" :class="[$i18n.locale == 'en' ? 'mt8 fm21 fs21 line25' : 'mt5 fm20 fs21 line30']">{{ $t('aboriginal.content.title1')}}</div>
      <div class="wid997" :class="[$i18n.locale == 'en' ? 'mt8 line25 fm14 fs21' : 'mt5 line30 fm12 fs21']"> {{ $t('aboriginal.content.title2')}}</div>
      <div class="wid997" :class="[$i18n.locale == 'en' ? 'mt4 line25 fm14 fs21' : 'center line30 fm12 fs21']">{{ $t('aboriginal.content.title4')}}</div>
      <div class="wid997 mt5" :class="[$i18n.locale == 'en' ? '  line25 fs21 fm21' : 'line30 fm20 fs21']">{{ $t('aboriginal.content.title5')}}</div>
      <div class="wid997" :class="[$i18n.locale == 'en' ? 'line25 fm14 fs21 mt8' : 'line30 fm12 fs21 mt5']">{{ $t('aboriginal.content.title6')}}</div>
      <div class="wid997 mt4" :class="[$i18n.locale == 'en' ? 'line25 fm14 fs21' : 'line30 fm12 fs21']">{{ $t('aboriginal.content.title7')}}</div>
      <!-- 滑动区域 -->
      <div class="content-swiper">
        <!-- 第一个 -->
        <div class="swiper-one">
          <div class="top">
            <span class="fs21" :class="[$i18n.locale == 'en' ? 'entop fm16' : 'chtop fm17']">{{$t('aboriginal.content.pic_one')}}</span>
            <span class="fs18" :class="[$i18n.locale == 'en' ? 'enspan2 fm22' : 'chspan2 fm17']">{{$t('aboriginal.content.content')}}</span>
          </div>
          <div class="bootom">
            <span class="mt25 line26 fs18" :class="[$i18n.locale == 'en' ? 'fm14' : 'fm12']">{{$t('aboriginal.content.title_one')}} </span>
            <span class="fs18 line26" :class="[$i18n.locale == 'en' ? 'fm14c' : 'fm12c']">{{$t('aboriginal.content.title_one1')}}</span>
            <span class="mt30 line26 fs18" :class="[$i18n.locale == 'en' ? 'fm14' : 'fm12']">{{$t('aboriginal.content.title_one2')}}</span>
            <span class="fs18 line26" :class="[$i18n.locale == 'en' ? 'fm14c' : 'fm12c']">{{$t('aboriginal.content.title_one3')}}</span>
            <img  :class="[$i18n.locale == 'en' ? 'enimg' : 'chimg']" src="../assets/images/abor_icon1.png" alt="" />
          </div>
        </div>
        <!-- 第二个 -->
        <div class="swiper-two">
          <div class="top">
            <span class="fs21" :class="[$i18n.locale == 'en' ? 'entop fm16' : 'chtop fm17']">{{$t('aboriginal.content.pic_two')}}</span>
            <span class="fs18" :class="[$i18n.locale == 'en' ? 'enspan2 fm22' : 'chspan2 fm17']">{{$t('aboriginal.content.title_two')}}</span>
          </div>
          <div class="bootom">
            <span class="fs18 line26 mt25" :class="[$i18n.locale == 'en' ? 'fm14':'fm12']">{{$t('aboriginal.content.title_two1')}} </span>
            <span class="fs18 line26" :class="[$i18n.locale == 'en' ? 'fm14c':'fm12c']">{{$t('aboriginal.content.title_two2')}}</span>
            <span class="fs18 line26 mt30" :class="[$i18n.locale == 'en' ? 'fm14':'fm12']">{{$t('aboriginal.content.title_two3')}}</span>
            <span class="fs18 line26" :class="[$i18n.locale == 'en' ? 'fm14c':'fm12c']">{{ $t('aboriginal.content.title_two4')}}</span>
            <span class="fs18 line26 mt20" :class="[$i18n.locale == 'en' ? 'fm14':'fm12']">{{ $t('aboriginal.content.title_two5')}}</span>
            <span class="fs18 line26" :class="[$i18n.locale == 'en' ? 'fm14c':'fm12c']">{{$t('aboriginal.content.title_two6')}}</span>
            <img  :class="[$i18n.locale == 'en' ? 'enimg' : 'chimg']"  src="../assets/images/abor_icon2.png" alt="" />
          </div>
        </div>
      </div>
      <div class="content-swiper">
        <!-- 第三个 -->
        <div class="swiper-three">
          <div class="top">
            <span class="fs21" :class="[$i18n.locale == 'en' ? 'entop fm16' : 'chtop fm17']">{{$t('aboriginal.content.pic_three')}}</span>
            <span class="fs18" :class="[$i18n.locale == 'en' ? 'enspan2 fm22' : 'chspan2 fm17']">{{$t('aboriginal.content.title_three1')}}</span>
          </div>
          <div class="bootom">
            <span class="fs18 line26 mt25" :class="[$i18n.locale == 'en' ? 'fm14':'fm12']">{{$t('aboriginal.content.title_three2')}}</span>
            <span class="fs18 line26" :class="[$i18n.locale == 'en' ? 'fm14c':'fm12c']">{{$t('aboriginal.content.title_three3')}}</span>
            <span class="fs18 line26 mt30" :class="[$i18n.locale == 'en' ? 'fm14':'fm12']">{{$t('aboriginal.content.title_three4')}}</span>
            <span class="fs18 line26" :class="[$i18n.locale == 'en' ? 'fm14c':'fm12c']">{{$t('aboriginal.content.title_three5')}}</span>
            <span class="fs18 line26 mt20" :class="[$i18n.locale == 'en' ? 'fm14':'fm12']">{{$t('aboriginal.content.title_three6')}}</span>
            <span class="fs18 line26" :class="[$i18n.locale == 'en' ? 'fm14c':'fm12c']">{{$t('aboriginal.content.title_three7')}}</span>
            <span class="fs16" :class="[$i18n.locale == 'en' ? 'mt6 wid200 fm14c' : 'mt76 fm12c']">{{$t('aboriginal.content.title_three8')}}</span>
            <img :class="[$i18n.locale=='en'?'enimg':'chimg']" src="../assets/images/abor_icon3.png" />
          </div>
        </div>
        <!-- 第四个 -->
        <div class="swiper-four">
          <div class="top">
            <span class="fs21" :class="[$i18n.locale == 'en' ? 'entop fm16' : 'chtop fm17']">{{$t('aboriginal.content.pic_four')}}</span>
            <span class="fs18" :class="[$i18n.locale == 'en' ? 'enspan2 fm22' : 'chspan2 fm17']">{{$t('aboriginal.content.title_four1')}}</span>
          </div>
          <div class="bootom">
            <span class="fs18 line26 mt25" :class="[$i18n.locale == 'en' ? 'fm14':'fm12']">{{$t('aboriginal.content.title_four2')}} </span>
            <span class="fs18 line26" :class="[$i18n.locale == 'en' ? 'fm14c':'fm12c']">{{$t('aboriginal.content.title_four3')}}</span>
            <span class="fs18 line26 mt30" :class="[$i18n.locale == 'en' ? 'fm14':'fm12']">{{ $t('aboriginal.content.title_four4')}}</span>
            <span class="fs18 line26" :class="[$i18n.locale == 'en' ? 'fm14c':'fm12c']">{{$t('aboriginal.content.title_four5')}}</span>
            <span class="fs18 line26" :class="[$i18n.locale == 'en' ? 'fm14c':'fm12c']">{{$t('aboriginal.content.title_four6')}}</span>
            <span class="fs18 line26 mt20" :class="[$i18n.locale == 'en' ? 'fm14':'fm12']" v-if="$i18n.locale != 'en'">创作权限</span>
            <span class="fs18 line26" :class="[$i18n.locale == 'en' ? 'fm14c':'fm12c']" v-if="$i18n.locale != 'en'">可获得两次字符创作提名权</span>
            <span :class="[$i18n.locale == 'en' ? 'line26 fm14c fs18 mt52':'line26 fm12c fs16 mt50']">{{$t('aboriginal.content.title_four7')}}</span>
            <img :class="[$i18n.locale == 'en' ? 'enimg' : 'chimg']" src="../assets/images/abor_icon4.png"/>
          </div>
        </div>
      </div>
      <!-- 黑色按钮部分 -->
      <div class="content-btn">
        <div>
            <span :style="{ width: $i18n.locale == 'en' ? '169px' : '124px',fontFamily:$i18n.locale == 'en' ? 'Montserrat-Bold':' NotoSansSC-Medium' }">{{$t('aboriginal.content.soon')}}</span>
        </div>
      </div>
      <div class="btn-bottom-text">
        <span :class="[$i18n.locale == 'en' ? 'fs16 fm14c span' : 'fs16 fm12c span']">{{$t('aboriginal.content.footer')}}</span>
      </div>
    </div>
    <footer-page></footer-page>
  </div>
</template>
<script>
// import headerPage from "@/components/header.vue"
import navPage from "@/components/header_nav.vue"//导航栏
import footerPage from "@/components/footer.vue"//脚部

export default {
  components:{navPage,footerPage},
  data() {
    return {
      show: false,
      opacityFlag:3,
    };
  },
};
</script>
<style scoped>
</style>
<style lang='less' scoped>
@import "../assets/css/commom.less";
.container-wrap {
  margin: 0 auto;
  height: 100%;
  width: 1500px;
}

// 导航栏下图片区域
.content-img {
  display: flex;
  img{
    min-width: 1500px;
    width: 100%;
    background-size: cover;
  }
}
// 中间文字区域
.content-wrap {
  margin: 0 auto 39px;
  height: 100%;
  width: 1500px;
  .chText1 {
    height: 90px;
    margin: 40px auto 0;
  }
  .enText1 {
    margin:44px auto 0;
  }
  .content-swiper {
    display: flex;
    align-items: center;
    width: 997px;
    margin: 0 auto;
    .swiper-one,
    .swiper-two,
    .swiper-three,
    .swiper-four {
      display: inline-block;
      width: 318px;
      height: 479px;
      margin-top: 46px;
      border: 1px solid #181726;
      margin-right: 26px;
      .top {
        height: 139px;
        position: relative;
        background: #181726;
        .entop, .chtop {
          position: absolute;
          top: 45px;
          left: 25px;
        }
        .enspan2, .chspan2 {
          position: absolute;
          top: 85px;
          left: 25px;
        }
      }
    }
    .swiper-one {
      .bootom {
        position: relative;
        span {
          display: block;
          margin-left: 17px;
        }
        .chimg, .enimg {
          position: absolute;
          left: 214px;
          margin-top: 85px;
        }
      }
    }
    .swiper-two {
      .bootom {
        position: relative;
        span {
          display: block;
          margin-left: 17px;
        }
        .chimg {
          position: absolute;
          left: 203px;
          margin-top: -8px;
        }
        .enimg {
          position: absolute;
          left: 203px;
          margin-top: -34px;
        }
      }
    }
    .swiper-three {
      .bootom {
        position: relative;
        span {
          display: block;
          margin-left: 17px;
        }
        span:nth-child(6){
           position: relative;
           z-index: 999;
        }
        .chimg {
          position: absolute;
          left: 206px;
          margin-top: -132px;
        }
        .enimg {
          position: absolute;
          left: 206px;
          margin-top: -128px;
        }
      }
    }
    .swiper-four {
      .bootom {
        position: relative;
        span {
          display: block;
          margin-left: 17px;
        }
        .enimg {
          position: absolute;
           left: 247px;
          margin-top: -118px;
        }
        .chimg {
          position: absolute;
          left: 247px;
          margin-top: -110px;
        }
      }
    }
  }
  .content-btn {
    display: flex;
    align-items: center;
    width: 997px;
    margin: 60px auto 6px;
    div {
      width: 311px;
      height: 61px;
      background: #181726;
      border-radius: 6px;
      line-height: 61px;
      span {
        font-size: 21px;
        font-weight: 500;
        color: #ffffff;
        margin: 0 auto;
        display: block;
        text-align: center;
      }
    }
  }
  .btn-bottom-text {
    display: flex;
    align-items: center;
    width: 997px;
    margin: 60px auto 6px;
    .span {
      display: block;
      width: 744px;
      line-height: 24px;
    }
  }
}
</style>
