<template>
  <div class="operation">
    <!-- header 头部-->
    <!-- <OperationHeader /> -->
    <!-- <div class="subject flex"> -->
    <!-- type 分类-->
    <!-- <OperationType /> -->
    <!-- 作品区域 -->
    <!-- <OperationWork /> -->
    <!-- </div> -->
    <Creattive />
    <!-- 预览图片 -->
    <PreviewPicture />
  </div>
</template>
<script>
// import OperationHeader from '../components/OperationHeader.vue'
// import OperationType from '../components/OperationType.vue'
// import OperationWork from '../components/OperationWork.vue'
import Creattive from '../components/Creattive.vue'
import PreviewPicture from '../components/PreviewPicture.vue'
export default {
  name: 'Operation',
  components: {PreviewPicture, Creattive },
  data() {
    return {
      flag: this.$route.query.flag
    }
  },
  mounted() {
    sessionStorage.setItem('bg', document.querySelector('.copy_dom').innerHTML)
  }
}
</script>
<style lang='less' scoped>
.operation {
  user-select: none;

  .subject {
    width: 100vw;
    height: calc(100vh - 3.125vw);
  }
}
</style>