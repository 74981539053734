import {
  request
} from "@/network/request";
// //获取分类列表
export function getTypeList() {
  return request({
    url: '/v1/explore/ds_list',
    method: 'get',
  })
}

// 查重接口
export function checkRepet(params) {
  return request({
    url: '/v1/item/ds_create',
    method: 'get',
    params: params
  });
}
// //获取不同分类中的图标
export function getImgList(data) {
  return request({
    url: '/v1/explore/metawords_io?size=1000',
    method: 'post',
    // params: {
    //   dsid: 1,
    //   cate,
    //   keyword
    // }
    data
  })
}
// //获取不同分类中的图标
export function getlist(data) {
  return request({
    url: '/NFT/character/queryCharacterIndex',
    method: 'post',
    data
  })
}

//查询模糊
export function getSearchList(data) {
  return request({
    url: '/NFT/character/searchCharacter',
    method: 'post',
    data
  })
}
// 上传图片
export function uploadFile(data) {
  return request({
    url: '/NFT/character/uploadCharacterFile',
    method: 'post',
    data
  })
}
// 上传字符及图片
export function saveUserCharacterText(data) {
  return request({
    url: '/NFT/character/saveUserCharacterText',
    method: 'post',
    data
  })
}
