import axios from 'axios'

// let baseURL =
//   process.env.NODE_ENV === "production"
//     ? "https://api.lionnft.io"
//     : "https://api.lionnft.net";
let baseURL = process.env.VUE_APP_BASEURL
export function request(config) {
  const instance = axios.create({
    // baseURL: 'https://metawords.io'//生产环境
    baseURL: baseURL//测试环境
  })

  //请求拦截器
  instance.interceptors.request.use(config => config, err => Promise.reject(err))

  //响应拦截器
  instance.interceptors.response.use(res => res, err => Promise.reject(err))

  return instance(config)
}


export function $get(url, data) {
  return request({
    url: url,
    method: 'get',
    params: data
  })
}

export function $post(url, data) {
  return request({
    url: url,
    method: 'post',
    data
  })
}
