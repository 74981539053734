<template>
  <div class="div_main">
    <div class="container">
      <img :src="src" width="99%" alt="" />
    </div>
    <div class="base">
      网站来源（推荐电脑访问）:<a
        style="font-size: 12px"
        :href="baseUrl"
        rel="noopener noreferrer"
        >{{ baseUrl }}</a
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      src: "",
      baseUrl: "",
    };
  },
  watch: {
    // 监听路由发生改变
    $route: {
      handler(newVal) {
        if (newVal.query.src) {
          this.src =
            "https://lion-nft-jpg.oss-cn-hongkong.aliyuncs.com/" +
            this.$route.query.src;
        }
      },
    },
  },
  mounted() {
    this.baseUrl = process.env.VUE_APP_BASEURL;
    this.src =
      "https://lion-nft-jpg.oss-cn-hongkong.aliyuncs.com/" +
      this.$route.query.src;
  },
};
</script>
<style lang="less" scoped>
.div_main {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.container {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // align-content: flex-start;
  padding: 0 2.5%;
  width: 95%;
  background: #fff;
  position: absolute;
  left: 50%; /* 定位父级的50% */
  top: 50%;
  transform: translate(-50%, -100%); /*自己的50% */
  // flex-wrap: wrap;
  img {
    max-width: 100vw;
  }
}
.base {
  font-size: 12px;
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 4%;
  transform: translate(-50%, -50%);
  width: 100vw;
}
</style>
